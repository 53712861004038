  
<template>
  <div
    class="doctorInfo_container"
    v-loading="loading"
    element-loading-text="数据上传中"
  >
    <!-- <ItemScroll
      class="doctorInfo_itemScroll"
      ref="ItemScroll"
      :isClearMove="false"
    > -->
    <div>
      <div class="doctor_info_title">完善个人信息</div>
      <div style="padding: 0 1rem">
        <div class="doctor_info_block_title">专家基本信息</div>
        <div class="update_doctor_info_block">
          <div class="doctor_info_block doctor_info_name">
            <div class="doctor_info_key"><span>*</span>姓名</div>
            <div class="global_text_input doctor_info_value">
              <el-input
                v-model.trim="doctorInfo.name"
                :disabled="true"
                placeholder="请输入姓名"
              ></el-input>
              <div
                @click="getMatchList()"
                class="docter_info_button docter_info_button_mate"
              >
                <span>匹配</span>
              </div>
              <div class="doctor-match-list" v-if="showDoctorList">
                <div class="doctor-match-title">
                  {{
                    doctors.length > 0
                      ? "为您匹配到系统中可能是您的信息，请点击确认"
                      : "系统中暂未匹配到您的相关数据，请填写"
                  }}
                </div>
                <div class="doctor-match-ul">
                  <div
                    class="doctor-match-li"
                    @click="selectDoctor(item)"
                    v-for="(item, index) in doctors"
                    :key="index"
                  >
                    {{ item.showName }}
                  </div>
                </div>
                <div class="doctor-match-button">
                  <CustomButton size="small" @click="showDoctorList = false"
                    >关闭</CustomButton
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="doctor_info_block">
            <div class="doctor_info_key"><span>*</span>医院</div>
            <div class="global_text_input doctor_info_value">
              <el-input
                v-model.trim="doctorInfo.hospital"
                placeholder="请输入医院名称"
              ></el-input>
            </div>
          </div>
          <div class="doctor_info_block">
            <div class="doctor_info_key"><span>*</span>专家科室</div>
            <div class="global_text_input doctor_info_value">
              <el-input
                v-model.trim="doctorInfo.department"
                placeholder="请输入专家科室"
              ></el-input>
            </div>
          </div>
          <div class="doctor_info_block">
            <div class="doctor_info_key"><span>*</span>主TA</div>
            <div class="global_text_input doctor_info_value">
              <el-input
                v-model.trim="doctorInfo.cancer_type"
                placeholder="请输入主TA"
              ></el-input>
            </div>
          </div>
          <div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">
                <span>*</span>省份（示例：湖北）
              </div>
              <div
                class="global_text_input global_text_select doctor_info_value"
              >
                <el-select
                  v-model="doctorInfo.province"
                  placeholder="请选择医生省份"
                  @change="changeProvince()"
                >
                  <el-option
                    v-for="(item, index) in provinces"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">
                <span>*</span>城市（示例：武汉）
              </div>
              <div
                class="global_text_input global_text_select doctor_info_value"
              >
                <el-select
                  v-model="doctorInfo.city"
                  placeholder="请选择医生城市"
                >
                  <el-option
                    v-for="(item, index) in citys"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="doctor_info_block">
            <div class="doctor_info_key">
              <span>*</span>专家职称（示例：住院/主治/副主任/主任医师）
            </div>
            <div class="global_text_input global_text_select doctor_info_value">
              <el-select
                v-model="doctorInfo.job_name"
                placeholder="请选择专家职称"
                @change="changeJobName"
              >
                <el-option
                  v-for="(item, index) in config.titles"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- 佐证材料 -->
          <div v-if="doctorInfo.job_name == '副主任医师' && getRoleZX()">
            <div class="doctor_info_block">
              <div class="doctor_info_key">
                上传材料
                <span style="color: #6f6f6f">（2选1）</span>
                <span style="color: red"
                  >（副主任医师作为主席参会需提供以下证明，否则最多支付2000元劳务费）</span
                >
              </div>
              <!-- <div class="doctor_info_key">
                  <div
                    style="color: red; margin-top: 0.5rem"
                    v-if="doctorInfo.cohosts_audit_status == 30"
                  >
                    审核失败:{{ doctorInfo.cohosts_audit_reason }}
                  </div>
                  <div
                    style="color: red; margin-top: 0.5rem"
                    v-if="doctorInfo.speaker_audit_status == 30"
                  >
                    审核失败:{{ doctorInfo.speaker_audit_reason }}
                  </div>
                </div> -->
            </div>
            <div>
              <div>
                <div class="doctor_info_block doctor_info_id_card_type">
                  <div
                    class="global_text_input doctor_info_value"
                    style="
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: flex-start;
                      height: auto;
                    "
                  >
                    <el-radio
                      v-model.number="bg_work_type"
                      :label="index"
                      v-for="(item, index) in config.bgworkType"
                      :key="index"
                      @change="bgChange"
                      >{{ item }}</el-radio
                    >
                  </div>
                </div>
                <div
                  class="doctor_info_block"
                  v-if="bg_work_type == 1"
                  v-for="item in 3"
                  :key="item"
                >
                  <div class="doctor_info_key" v-if="item == 1">
                    国内核心期刊或国际期刊上发表3篇以上论文
                  </div>
                  <div class="doctor_info_value global_container_center">
                    <div
                      class="doctor_info_image-block"
                      @click="selectFileImg('image_three_post', item)"
                    >
                      <CustomImage
                        :src="image_three_post[item - 1]"
                        v-if="image_three_post[item - 1]"
                        alt=""
                      />
                      <div class="doctor_info_image-select" v-else>
                        <i
                          v-show="!image_three_post[item - 1]"
                          class="el-icon-plus"
                        ></i>
                        <span v-if="item == 1"> 第一篇文献原文首页截图 </span>
                        <span v-if="item == 2"> 第二篇 </span>
                        <span v-if="item == 3"> 第三篇 </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="doctor_info_block" v-if="bg_work_type == 0">
                  <div class="doctor_info_key">
                    国家级协会/学会任职 (前任/现任或候任) 的证明
                  </div>
                  <div class="doctor_info_value global_container_center">
                    <div
                      class="doctor_info_image-block"
                      @click="selectFileImg('image_other_job')"
                    >
                      <CustomImage
                        :src="doctorInfo.image_other_job"
                        v-if="doctorInfo.image_other_job"
                        alt=""
                      />
                      <div class="doctor_info_image-select" v-else>
                        <i
                          v-show="!doctorInfo.image_other_job"
                          class="el-icon-plus"
                        ></i>
                        <span>
                          上传国家级协会/学会任职 (前任/现任或候任) 的证明
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="doctorInfo.job_name == '医师（住院医师）' && getRoleTLjz()"
          >
            <div class="doctor_info_block">
              <div class="doctor_info_key">
                上传材料
                <span style="color: red"
                  >（3年以上住院医师方可参与活动，请提供相关证明）</span
                >
              </div>
              <!-- <div class="doctor_info_key">
                  <div
                    style="color: red; margin-top: 0.5rem"
                    v-if="doctorInfo.cohosts_audit_status == 30"
                  >
                    审核失败:{{ doctorInfo.cohosts_audit_reason }}
                  </div>
                  <div
                    style="color: red; margin-top: 0.5rem"
                    v-if="doctorInfo.speaker_audit_status == 30"
                  >
                    审核失败:{{ doctorInfo.speaker_audit_reason }}
                  </div>
                </div> -->
            </div>
            <div>
              <div class="doctor_info_block">
                <div class="doctor_info_key">3年以上的临床工作经验证明</div>
                <div class="doctor_info_value global_container_center">
                  <div
                    class="doctor_info_image-block"
                    @click="selectFileImg('image_into_job')"
                  >
                    <CustomImage
                      :src="doctorInfo.image_into_job"
                      v-if="doctorInfo.image_into_job"
                      alt=""
                    />
                    <div class="doctor_info_image-select" v-else>
                      <i
                        v-show="!doctorInfo.image_into_job"
                        class="el-icon-plus"
                      ></i>
                      <span> 上传3年以上的临床工作经验证明 </span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="doctorInfo.job_name == '副主任医师' && getRoleZX()">
                <div class="doctor_info_block doctor_info_id_card_type">
                  <div class="global_text_input doctor_info_value">
                    <el-radio
                      v-model.number="bg_work_type"
                      :label="index"
                      v-for="(item, index) in config.bgworkType"
                      :key="index"
                      @change="bgChange"
                      >{{ item }}</el-radio
                    >
                  </div>
                </div>
                <div
                  class="doctor_info_block"
                  v-if="bg_work_type == 1"
                  v-for="item in 3"
                  :key="item"
                >
                  <div class="doctor_info_key" v-if="item == 1">
                    国内核心期刊或国际期刊上发表3篇以上论文
                  </div>
                  <div class="doctor_info_value global_container_center">
                    <div
                      class="doctor_info_image-block"
                      @click="selectFileImg('image_three_post', item)"
                    >
                      <CustomImage
                        :src="image_three_post[item - 1]"
                        v-if="image_three_post[item - 1]"
                        alt=""
                      />
                      <div class="doctor_info_image-select" v-else>
                        <i
                          v-show="!image_three_post[item - 1]"
                          class="el-icon-plus"
                        ></i>
                        <span> 国内核心期刊或国际期刊上发表3篇以上论文 </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="doctor_info_block" v-if="bg_work_type == 0">
                  <div class="doctor_info_key">
                    国家级协会/学会任职 (前任/现任或候任) 的证明
                  </div>
                  <div class="doctor_info_value global_container_center">
                    <div
                      class="doctor_info_image-block"
                      @click="selectFileImg('image_other_job')"
                    >
                      <CustomImage
                        :src="doctorInfo.image_other_job"
                        v-if="doctorInfo.image_other_job"
                        alt=""
                      />
                      <div class="doctor_info_image-select" v-else>
                        <i
                          v-show="!doctorInfo.image_other_job"
                          class="el-icon-plus"
                        ></i>
                        <span>
                          上传国家级协会/学会任职 (前任/现任或候任) 的证明
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 专家职称证明 -->
          <div class="doctor_info_block">
            <div class="doctor_info_key">
              <span>*</span>
              专家职称证明
              <span style="color: #6f6f6f"
                >（可上传1-3张，请务必包含专家姓名、医院、科室、职称信息，否则无法审核通过）</span
              >
              <p
                style="color: #f00"
                v-if="doctorInfo.work_card_ocr_status === 0"
              >
                人工审核中
              </p>
              <p
                style="color: #f00"
                v-if="doctorInfo.work_card_ocr_status === 2"
              >
                人工审核反馈：{{ doctorInfo.work_card_ocr_reason }}
              </p>
            </div>
          </div>
          <div>
            <div class="doctor_info_block doctor_info_id_card_type">
              <div class="global_text_input doctor_info_value">
                <el-radio
                  v-model.number="doctorInfo.work_type"
                  :label="index"
                  v-for="(item, index) in config.workType"
                  :key="index"
                  @change="changeWorkType"
                  >{{ item }}</el-radio
                >
              </div>
            </div>
            <div class="doctor_info_block" v-if="doctorInfo.work_type == 0">
              <div class="doctor_info_key">医生ID卡</div>
              <div class="doctor_info_value global_container_center">
                <div
                  class="doctor_info_image-block"
                  @click="selectFileImg('doctor_work_id_card')"
                >
                  <CustomImage
                    :src="doctorInfo.doctor_work_id_card"
                    v-if="doctorInfo.doctor_work_id_card"
                    alt=""
                  />
                  <div class="doctor_info_image-select" v-else>
                    <i
                      v-show="!doctorInfo.doctor_work_id_card"
                      class="el-icon-plus"
                    ></i>
                    <span> 上传医生ID卡 </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="doctor_info_block" v-if="doctorInfo.work_type == 1">
              <div class="doctor_info_key">
                官网全景图（需截到官网网址链接）
              </div>
              <div class="doctor_info_value global_container_center">
                <div
                  class="doctor_info_image-block"
                  @click="selectFileImg('professional_panoramic')"
                >
                  <CustomImage
                    :src="doctorInfo.professional_panoramic"
                    v-if="doctorInfo.professional_panoramic"
                    alt=""
                  />
                  <div class="doctor_info_image-select" v-else>
                    <i
                      v-show="!doctorInfo.professional_panoramic"
                      class="el-icon-plus"
                    ></i>
                    <span> 上传官网全景图 </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="doctor_info_block" v-if="doctorInfo.work_type == 1">
              <div class="doctor_info_key">官网医生身份信息截图页</div>
              <div class="doctor_info_value global_container_center">
                <div
                  class="doctor_info_image-block"
                  @click="selectFileImg('professional_person')"
                >
                  <CustomImage
                    :src="doctorInfo.professional_person"
                    v-if="doctorInfo.professional_person"
                    alt=""
                  />
                  <div class="doctor_info_image-select" v-else>
                    <i
                      v-show="!doctorInfo.professional_person"
                      class="el-icon-plus"
                    ></i>
                    <span> 上传官网医生身份信息截图页 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="doctor_info_block">
            <div class="doctor_info_key"><span>*</span>行政职务</div>
            <div class="global_text_input doctor_info_value">
              <el-input
                v-model.trim="doctorInfo.job"
                placeholder="请输入行政职务"
              ></el-input>
            </div>
          </div>

          <div class="doctor_info_block">
            <div class="doctor_info_key">
              <span>*</span>专家简历/学术任职
              <span style="color: red">（该内容将用于制作串场幻灯）</span>
            </div>

            <div
              class="dcotorInfo_textarea doctor_info_value"
              ref="textareaRef"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 5 }"
                v-model="doctorInfo.resume_academic"
                placeholder="请输入专家简历/学术任职"
              ></el-input>
            </div>
          </div>
          <div class="doctor_info_block">
            <div class="doctor_info_key">
              <span>*</span>简历图片<span style="color: red"
                >(请上传近期照片，贴合白色轮廓)</span
              >
            </div>
            <div
              class="doctor_info_professional_cover_container global_text_select doctor_info_value"
            >
              <div
                class="doctor_info_professional_cover"
                @click="selectFile('professional_cover')"
              >
                <CustomImage
                  :src="doctorInfo.professional_cover"
                  v-if="isShowField('professional_cover')"
                  alt=""
                />
                <img
                  v-else
                  class="doctor_info_frame"
                  :src="require('@/assets/images/home/resume_img_have.png')"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div style="color: red; margin-bottom: 0.5rem; font-size: 0.8rem">
          专家基本信息必须完成，方可参与项目，生成相关物料
        </div>
        <div class="save_info_button_block">
          <div class="save_info_button" @click.native="doCommit()">保存</div>
        </div>
        <div style="color: red; margin-bottom: 0.5rem; font-size: 0.8rem">
          专家协议信息可会后补充，协议信息完整方可生成协议
        </div>
        <div class="doctor_info_block_title">专家协议信息</div>
        <div class="update_doctor_info_block">
          <div class="doctor_info_block">
            <div class="doctor_info_key">手机号</div>
            <div class="global_text_input doctor_info_value">
              <el-input
                disabled
                v-model.trim="doctorInfo.phone"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="doctor_info_block">
            <div class="doctor_info_key">
              身份证号
              <p
                style="color: #f00"
                v-if="
                  doctorInfo.id_card_ocr_status === 0 &&
                  doctorInfo.id_card_front &&
                  doctorInfo.id_card_reverse &&
                  doctorInfo.id_card_number &&
                  doctorInfo.bank_name_point &&
                  doctorInfo.bank_number &&
                  doctorInfo.bank_province &&
                  doctorInfo.bank_city &&
                  doctorInfo.bank_name
                "
              >
                人工审核中
              </p>
              <p
                style="color: #f00"
                v-if="
                  doctorInfo.id_card_ocr_status === 2 &&
                  doctorInfo.id_card_front &&
                  doctorInfo.id_card_reverse &&
                  doctorInfo.id_card_number &&
                  doctorInfo.bank_name_point &&
                  doctorInfo.bank_number &&
                  doctorInfo.bank_province &&
                  doctorInfo.bank_city &&
                  doctorInfo.bank_name
                "
              >
                人工审核反馈：{{ doctorInfo.id_card_ocr_reason }}
              </p>
            </div>

            <div class="global_text_input doctor_info_value">
              <el-input
                v-model.trim="doctorInfo.id_card_number"
                clearable
                @input="inputText('id_card_number')"
                placeholder="请输入身份证号"
              ></el-input>
            </div>
          </div>
          <div class="doctor_info_block">
            <!-- <div class="doctor_info_key">
                身份证照片
                <p style="color: #f00" v-if="doctorInfo.id_card_ocr_status === 2">
                  身份证审核反馈：{{ doctorInfo.id_card_reason }}
                </p>
              </div> -->
            <div>
              <div class="doctor_info_value global_container_center">
                <div
                  class="doctor_info_image-block"
                  @click="selectFile('id_card_front')"
                >
                  <CustomImage
                    :src="doctorInfo.id_card_front"
                    v-if="isShowField('id_card_front')"
                    alt=""
                  />
                  <div class="doctor_info_image-select" v-else>
                    <i
                      v-show="!doctorInfo.id_card_front"
                      class="el-icon-plus"
                    ></i>
                    <span>{{
                      getShowName("id_card_front", "上传身份证人像面")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="doctor_info_value global_container_center">
                <div
                  class="doctor_info_image-block"
                  @click="selectFile('id_card_reverse')"
                >
                  <CustomImage
                    :src="doctorInfo.id_card_reverse"
                    v-if="isShowField('id_card_reverse')"
                    alt=""
                  />
                  <div class="doctor_info_image-select" v-else>
                    <i
                      v-show="!doctorInfo.id_card_reverse"
                      class="el-icon-plus"
                    ></i>
                    <span>{{
                      getShowName("id_card_reverse", "上传身份证国徽面")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 银行账号 -->
          <div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">银行（示例：交通银行）</div>
              <div class="global_text_input doctor_info_value">
                <el-input
                  v-model.trim="doctorInfo.bank_name"
                  placeholder="请输入银行"
                ></el-input>
              </div>
            </div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">开户行所在省份（示例：湖北）</div>
              <div
                class="global_text_input global_text_select doctor_info_value"
              >
                <el-select
                  v-model="doctorInfo.bank_province"
                  placeholder="请选择开户行所在省份"
                  @change="changeProvince('bank')"
                >
                  <el-option
                    v-for="(item, index) in provinces"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">开户行所在城市（示例：武汉）</div>
              <div
                class="global_text_input global_text_select doctor_info_value"
              >
                <el-select
                  v-model="doctorInfo.bank_city"
                  placeholder="请选择开户行所在城市"
                >
                  <el-option
                    v-for="(item, index) in bankCitys"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">开户银行（xx支行/xx营业部）</div>
              <div class="global_text_input doctor_info_value">
                <el-input
                  v-model.trim="doctorInfo.bank_name_point"
                  placeholder="请输入开户银行"
                ></el-input>
              </div>
            </div>
            <div class="doctor_info_block">
              <div class="doctor_info_key">银行账号</div>
              <div class="global_text_input doctor_info_value">
                <el-input
                  v-model.trim="doctorInfo.bank_number"
                  clearable
                  placeholder="请输入银行账号"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="save_info_button_block">
          <div class="save_info_button" @click.native="doCommit()">保存</div>
        </div>
      </div>
    </div>
    <!-- </ItemScroll> -->
    <div class="doctor_info_block" style="padding: 0 1rem">
      <el-button
        class="submit"
        type="primary"
        @click.native="doCommit('all')"
        round
      >
        <span>提交信息</span>
      </el-button>
    </div>
    <image-cropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="getCropperCfg().cropWidth"
      :CropHeight="getCropperCfg().cropHeight"
      :flexScale="getCropperCfg().flexScale"
      :MaxSize="getCropperCfg().MaxSize"
      :isShowHeadimgCalibration="getCropperCfg().isShowHeadimgCalibration"
      :uploadUrl="getCropperCfg().uploadUrl"
      v-if="cropperManager.url"
    ></image-cropper>
    <Popup
      v-if="showPopup"
      :msg="config.msg"
      @doClose="doClose"
      @doSure="doSure"
    >
      <div class="doctor-popup-main">
        <div class="doctor-popup-content global_container_center">
          {{ config.msg.content }}
        </div>
        <div class="popup-button-group">
          <CustomButton size="small" type="info" @click="showPopup = false"
            >点击补充</CustomButton
          >
          <CustomButton size="small" @click="applyAudit"
            >我上传的信息齐全，申请人工审核</CustomButton
          >
        </div>
      </div>
    </Popup>
    <input
      type="file"
      v-show="false"
      v-if="cropperManager.resetFile"
      @change="changeFile"
      ref="selectfiles"
    />
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFileImg"
      ref="selectFileImg"
    />
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
import ImageCropper from "@/components/unit/ImageCropper";
import Popup from "@/components/unit/Popup.vue";
import CustomImage from "@/components/unit/CustomImage.vue";
import CustomButton from "@/components/unit/CustomButton.vue";
import uploads from "@/common/uploads";
import PopopInstance from "@/components/unit/PopupInstance";
import weixin from "@/common/weixin";
export default {
  name: "doctorInfo",
  components: {
    ItemScroll,
    ImageCropper,
    CustomButton,
    CustomImage,
    Popup,
  },

  data() {
    return {
      config: {
        cropperCfg: {
          professional_cover: {
            cropWidth: 225,
            cropHeight: 225,
            flexScale: [1, 1],
            MaxSize: 2,
            isShowHeadimgCalibration: true,
            uploadUrl: "https://files.medflying.com/file_system/filesbase64",
          },
          other: {
            cropWidth: 300,
            cropHeight: 189,
            flexScale: [8.56, 5.4],
            MaxSize: 2,
            isShowHeadimgCalibration: false,
            uploadUrl: "https://files.medflying.com/file_system/filesbase64",
          },
        },
        msg: {
          title: "提示",
          hide: true,
          align: "center",
        },
        titles: ["医师（住院医师）", "主治医师", "副主任医师", "主任医师"], // 职称列表
        citys: [],
        workType: ["医生ID卡", "官网医生身份信息截图页"],
        bgworkType: [
          "国家级协会/学会任职 (前任/现任或候任) 的证明",
          "国内核心期刊或国际期刊上发表3篇以上论文",
        ],
      },
      resetFile: true,
      selectKey: "",
      showPopup: false,
      hideField: [
        "phone",
        "id_card_number",
        "bank_number",
        "id_card_front",
        "id_card_reverse",
        // "doctor_work_id_card",
        // "professional_person",
        // "professional_panoramic",
        // "professional_cover",
      ],

      cropperManager: {
        key: "",
        resetFile: true,
        url: "",
      },
      doctors: [],
      doctorInfo: {
        id: "",
        openid: "",
        name: "",
        hospital: "",
        department: "",
        job_name: "",
        province: "",
        city: "",
        work_type: "",
        doctor_work_id_card: "",
        job: "",
        resume_academic: "",
        professional_cover: "",
        professional_person: "",
        professional_panoramic: "",

        image_three_post: "",
        image_other_job: "",
        image_into_job: "",

        phone: "",
        id_card_number: "",
        bank_province: "",
        bank_city: "",
        bank_name: "",
        bank_name_point: "",
        bank_number: "",
        id_card_front: "",
        id_card_reverse: "",
        id_card_ocr_status: "",
        id_card_reason: "",
      },
      loading: false,
      showDoctorList: false,
      bg_work_type: 0,
      image_three_post: [],
    };
  },
  watch: {},
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
  },
  mounted() {
    this.user_id = this.$route.query.user_id || "";
    this.initDoctorData();
    this.$tools.removeTouch();
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.doctorInfo.province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
    bankCitys() {
      if (!this.doctorInfo.bank_province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.bank_province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  methods: {
    async initDoctorData() {
      this.$axios
        .get(this.$urls.user.info, this.getUserId())
        .then((res) => {
          this.$store.dispatch("setItem", { userInfo: res.data });
          this.doctorInfo = res.data;

          this.bg_work_type = this.doctorInfo.image_other_job ? 0 : 1;
          this.image_three_post = this.doctorInfo.image_three_post.split(",");
          this.hideData();
          this.initScroll();
        })
        .catch(() => {});
    },
    getUserId() {
      return {
        "user-id": this.user_id || this.$store.state.common.userId,
      };
    },
    // 主席
    getRoleZX() {
      return (
        this.doctorInfo.roles.filter((v) => v.role_name == "主席").length > 0
      );
    },
    // 讨论 讲者
    getRoleTLjz() {
      return (
        this.doctorInfo.roles.filter((v) => v.role_name != "主席").length > 0
      );
    },
    changeJobName(e) {
      let temp = ["副主任医师", "主任医师"];
      if (this.getRoleZX() && !temp.includes(e)) {
        this.$tips.error({
          text: "根据项目需求，主席专家仅支持主任/副主任医师参加",
        });
        this.doctorInfo.job_name = "";
      }
      this.doctorInfo.image_into_job = "";
      this.doctorInfo.image_other_job = "";
      this.image_three_post = [];
      this.doctorInfo.image_three_post = "";

      this.bg_work_type = 0;
    },
    isShowField(key) {
      return this.doctorInfo[key] && !this.doctorInfo[key].includes("*");
    },
    changeProvince(key) {
      if (key == "bank") {
        this.doctorInfo.bank_city = "";
      } else {
        this.doctorInfo.city = "";
      }
    },
    //申请人工审核
    applyAudit() {
      this.$axios
        .patch(this.$urls.user.audit, {}, this.getUserId())
        .then((res) => {
          this.$tips.success({ text: "申请人工审核成功" });
          this.showPopup = false;
          this.doctorInfo.work_card_ocr_status = 0;
          this.$router.push({
            path: "/home",
          });
        })
        .catch((err) => {
          print(err);
          if (!err.errcode) {
            this.$tips.error({ text: "申请人工审核失败" });
          }
          console.log(err);
        });
    },
    //数据脱敏
    hideData() {
      for (let key of this.hideField) {
        this.doctorInfo[key] = this.doctorInfo[key] || "";
        if (!this.doctorInfo[key]) continue;
        let startLen = 0;
        let endLen = 0;
        switch (key) {
          //手机号保留后4位
          case "phone":
            startLen = 0;
            endLen = -4;
            break;
          //身份证号保留后6位
          case "id_card_number":
            startLen = 0;
            endLen = -6;
            break;
          //银行卡号保留前3位后3位
          case "bank_number":
            startLen = 3;
            endLen = -3;
            break;
          //其他的为链接,保留域名与文件名后3位:http://ioclassmedflying.com********921.png(前台不做处理,显示已上传)
          default:
            startLen = 8;
            endLen = -7;
        }
        this.doctorInfo[key] =
          this.doctorInfo[key].slice(0, startLen) +
          "****" +
          this.doctorInfo[key].slice(endLen);
        console.log(this.doctorInfo[key], key);
      }
    },
    getShowName(key, label) {
      return this.doctorInfo[key] ? "已上传" : label;
    },

    selectFileImg(key, index) {
      this.image_three_post_index = index - 1;

      this.selectKey = key;
      this.$refs.selectFileImg.click();
    },
    //上传图片(不裁图)
    async changeFileImg(event) {
      let file = event.target.files[0];
      this.resetFile = false;
      let loading = this.$tips.loading();
      this.$nextTick(() => {
        this.resetFile = true;
      });
      try {
        let res = await uploads.uploadFile(
          file,
          "jpg|jpeg|png|PNG|JPG|JPEG",
          "files",
          ""
        );
        loading.close();

        if (this.selectKey == "image_three_post") {
          this.image_three_post[this.image_three_post_index] = res.data[0];
        } else {
          this.doctorInfo[this.selectKey] = res.data[0];
        }
      } catch (err) {
        console.log(err);
        loading.close();
        // this.$tips.error({ text: "上传失败,请重新上传" });
      }
    },

    selectLocalFile() {
      this.$refs.selectfiles.click();
    },
    getCropperCfg() {
      let key =
        this.cropperManager.key == "professional_cover"
          ? "professional_cover"
          : "other";
      return this.config.cropperCfg[key];
    },
    selectFile(key, index) {
      this.image_three_post_index = index - 1;
      if (this.loading || !this.cropperManager.resetFile) return;
      this.cropperManager.key = key;
      this.selectLocalFile(key);
    },
    changeFile(event) {
      let reg = "jpg|jpeg|png|PNG|JPG|JPEG";
      let file = event.target.files[0];
      if (!file) return;
      let RegObj = new RegExp(`^(${reg})$`);

      let type = file.name.split(".").slice(-1);
      if (!RegObj.test(type)) {
        this.$tips.error({ text: `类型必须是${reg.replace(/\|/g, ",")}` });
        return "";
      }

      const loading = this.$tips.loading();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (item) => {
        this.cropperManager.url = item.target.result;
        this.cropperManager.resetFile = false;
        loading.close();
        this.$nextTick(() => {
          this.cropperManager.resetFile = true;
          this.$refs.imageCropper.setData(this.cropperManager.url);
          setTimeout(() => {}, 2000);
        });
      };
    },
    receiveData(data) {
      if (this.cropperManager.key.includes("image_three_post")) {
        // this[this.cropperManager.key][this.image_three_post_index] = data.url;
      } else {
        this.doctorInfo[this.cropperManager.key] = data.url;
      }
      this.cropperManager.url = "";
    },
    changeWorkType() {},
    checkData() {
      if (!this.doctorInfo.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.doctorInfo.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.doctorInfo.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      if (!this.doctorInfo.cancer_type) {
        this.$tips.error({ text: "请输入主TA" });
        return false;
      }

      if (!this.doctorInfo.province) {
        this.$tips.error({ text: "请选择医生省份" });
        return false;
      }
      if (!this.doctorInfo.city) {
        this.$tips.error({ text: "请选择医生城市" });
        return false;
      }
      if (!this.doctorInfo.job_name) {
        this.$tips.error({ text: "请选择职称" });
        return false;
      }

      if (
        this.doctorInfo.job_name == "医师（住院医师）" &&
        this.getRoleTLjz() &&
        !this.doctorInfo.image_into_job
      ) {
        this.$tips.error({ text: "请上传三年以上的临床工作经验证明" });
        return false;
      }
      //没有医生ID卡才必填，有ID卡则选填
      if (!this.doctorInfo.doctor_work_id_card) {
        if (!this.doctorInfo.professional_panoramic) {
          this.$tips.error({ text: "请上传官网全景图" });
          return false;
        }
        if (!this.doctorInfo.professional_person) {
          this.$tips.error({ text: "请上传官网医生身份信息截图页" });
          return false;
        }
      }
      if (!this.doctorInfo.job) {
        this.$tips.error({ text: "请输入行政职务" });
        return false;
      }
      if (!this.doctorInfo.resume_academic) {
        this.$tips.error({ text: "请输入专家简历/学术任职" });
        return false;
      }
      if (this.doctorInfo.resume_academic.length < 10) {
        this.$tips.error({ text: "专家简历/学术任职最少十个字" });
        return false;
      }

      if (Math.round((this.$refs.textareaRef.offsetHeight - 10) / 20) > 16) {
        this.$tips.error({ text: "专家简历/学术任职上限为16行，请删减行数" });
        return false;
      }

      if (!this.doctorInfo.professional_cover) {
        this.$tips.error({ text: "请上传简历图片" });
        return false;
      }

      //以下信息可在在会后补充
      if (this.user_id || this.$route.query.type) {
        if (!this.doctorInfo.phone.includes("****")) {
          if (!this.doctorInfo.phone) {
            this.$tips.error({ text: "请输入手机号" });
            return false;
          }
          if (!/^1\d{10}$/g.test(this.doctorInfo.phone)) {
            this.$tips.error({ text: "请输入格式正确的手机号" });
            return false;
          }
        }
        if (!this.doctorInfo.id_card_number.includes("****")) {
          if (!this.doctorInfo.id_card_number) {
            this.$tips.error({ text: "请输入身份证号" });
            return false;
          }
          let reg =
            /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
          if (!reg.test(this.doctorInfo.id_card_number)) {
            this.$tips.error({ text: "身份证号不合法" });
            return false;
          }
        }
        if (!this.doctorInfo.id_card_front) {
          this.$tips.error({ text: "请上传身份证人像面" });
          return false;
        }
        if (!this.doctorInfo.id_card_reverse) {
          this.$tips.error({ text: "请上传身份证国徽面" });
          return false;
        }
        if (!this.doctorInfo.bank_name) {
          this.$tips.error({ text: "请输入银行" });
          return false;
        }
        if (!this.doctorInfo.bank_province) {
          this.$tips.error({ text: "请选择银行省份" });
          return false;
        }
        if (!this.doctorInfo.bank_city) {
          this.$tips.error({ text: "请选择银行城市" });
          return false;
        }
        if (!this.doctorInfo.bank_name_point) {
          this.$tips.error({ text: "请输入开户银行" });
          return false;
        }
        if (!this.doctorInfo.bank_number.includes("****")) {
          if (!this.doctorInfo.bank_number) {
            this.$tips.error({ text: "请输入银行账号" });
            return false;
          }
          if (
            !/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(
              this.doctorInfo.bank_number
            )
          ) {
            this.$tips.error({ text: "错误的银行账号" });
            return false;
          }
        }
      }
      return true;
    },
    inputText(key) {
      if (this.doctorInfo.id_card_number.includes("****")) {
        this.doctorInfo[key] = "";
      }
    },
    initScroll(status = false) {
      this.$nextTick(() => {
        // this.$refs.ItemScroll.initScroll(true);
      });
    },
    bgChange() {
      // this.initScroll();
    },

    isHide(data) {
      return (String(data) || "").includes("*");
    },

    formatInfo() {
      let data = { ...this.doctorInfo };
      for (let key in data) {
        if (data[key] === null) data[key] = "";
      }
      data.image_three_post = this.image_three_post.join(",");
      return data;
    },
    //提交医生信息
    doCommit(type) {
      // console.log("this.formatInfo() :>> ", this.formatInfo());
      // return;
      if (!this.checkData() || this.loading) return;
      this.loading = true;
      let currLoading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.user.info, this.formatInfo(), this.getUserId())
        .then((res) => {
          let msg = {};
          setTimeout(() => {
            currLoading.close();
            switch (res.code) {
              case 2002:
                this.config.msg.content = res.msg;
                this.showPopup = true;

                break;
              case 2001:
                // this.$tips.error({ text: res.msg });
                msg = {
                  title: "温馨提示",
                  html: `<div class="popup-content-main">身份信息审核不通过，请联系会议执行人员进行人工审核！</div>`,
                  btngroup: { sure: "确认" },
                  align: "center",
                };
                PopopInstance(msg).then((res) => {
                  if (this.$route.query.from == "/agreement") {
                    if (this.$route.query.type == 1) {
                      //系统进
                      this.$router.push({
                        path: "/home",
                        query: {
                          ...this.$route.query,
                        },
                      });
                    } else {
                      weixin.wx.closeWindow(); //模板进
                    }
                  } else {
                    this.$router.push({
                      path: this.$route.query.from || "/home",
                      query: {
                        ...this.$route.query,
                      },
                    });
                  }
                });
                break;
              default:
                this.$tips.success({ text: "修改成功" });
                setTimeout(() => {
                  if (type == "all") {
                    this.loading = false;
                    this.$router.push({
                      path: this.$route.query.from || "/home",
                      query: {
                        ...this.$route.query,
                      },
                    });
                  }
                }, 500);
            }
            this.loading = false;
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
            currLoading.close();
          }, 1000);
        });
    },
    selectDoctor(doctor) {
      this.doctorInfo = {
        ...this.doctorInfo,
        ...doctor,
        phone: this.doctorInfo.phone,
      };
      this.doctorInfo.work_type = Number(this.doctorInfo.work_type) || 0;
      // this.hideData();
      this.showDoctorList = false;
    },
    //匹配基本信息
    getMatchList() {
      if (this.isMatching) return;
      this.isMatching = true;
      let loading = this.$tips.loading({ text: "匹配中..." });
      let url = this.$tools.getURL(this.$urls.user.match, {
        name: this.doctorInfo.name,
      });
      this.$axios
        .get(url)
        .then((res) => {
          setTimeout(() => {
            this.isMatching = false;
          }, 1000);
          loading.close();
          this.doctors = [...res.data].map((doctor) => {
            doctor.showName = `${doctor.name}-${doctor.hospital}`;
            return doctor;
          });
          this.showDoctorList = true;
        })
        .catch((err) => {
          setTimeout(() => {
            this.isMatching = false;
          }, 1000);
          loading.close();
        });
    },
    doClose() {
      this.showPopup = false;
    },
    doSure() {
      this.config.isPopupShow = false;
    },
  },
};
</script>
<style>
.doctorInfo_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  /* padding: 0 1rem; */
  /* margin: 1rem; */
  background: #f5f5f5;
}

.doctorInfo_container .el-input.is-disabled .el-input__inner,
.doctorInfo_container .el-textarea.is-disabled .el-textarea__inner {
  background: #fff;
}

.el-textarea__inner {
  font-size: 0.8rem;
}

.doctorInfo_itemScroll {
  flex: 1 0 0;
  margin-bottom: 0.5rem;
}

.doctor_info_block {
  margin-bottom: 1rem;
}

.doctor_info_block .el-button {
  width: 100%;
}
.doctor_info_id_card_type .global_text_input {
  padding: 0 1rem;
}

.doctor_info_key {
  color: #6f6f6f;
  font-size: 0.8rem;
}

.doctor_info_key span {
  color: red;
}

.doctor_info_value {
  margin-top: 0.5rem;
  /* padding-right: 0.5rem; */
  display: flex;
  justify-content: space-between;
  color: #6a6266;
  word-break: break-all;
  position: relative;
}

.doctor_info_image-block {
  width: 19rem;
  height: 13.5rem;
  background: #fff;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f9f9f;
}

.doctor_info_image-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.doctor_info_image-select i {
  font-size: 0.5rem;
  line-height: 3rem;
}

.disable_background,
.disable_background .el-input.is-disabled .el-input__inner {
  background-color: #cccccc;
  color: #000;
}

.docter_info_button {
  flex: 3.5rem 0 0;
  height: 100%;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  line-height: 1.75rem;
  text-align: center;
  border: 1px solid #e64638;
  color: #000;
}

.update_button_group {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.cancle_button {
  margin-right: 1rem;
}

.update_button {
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  text-align: center;
  border: 1px solid #e64638;
  color: #000;
}

.doctorInfo_container .doctor_info_tips {
  margin: 0.5rem 0;
}

.update_doctor_info_block {
  padding-bottom: 1.25rem;
  border: 1px solid #e64638;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  /* background-color: #a1a1a1; */
}

.save_info_button_block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.save_info_button {
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  text-align: center;
  border: 1px solid #e64638;
  background: #e64638;
  color: #fff;
  font-size: 1rem !important;
  width: 5rem;
}

.doctor_info_block_title {
  color: #e64638;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.doctor_info_professional_cover {
  width: 10rem;
  height: 10rem;
}

.doctor_info_professional_cover img {
  width: 100%;
  border: 1px solid #ccc;
}

.doctor_info_frame {
  width: 100%;
  height: 100%;
}

.doctor_info_title {
  background: #e64638;
  color: #fff;
  padding: 0.75rem 0;
  text-align: center;
  margin-bottom: 1rem;
}

.docter_info_button_mate {
  background: #e64638;
  color: #fff;
  border: none;
}

.doctorInfo_container .submit {
  background: #e64638;
  border: none;
  font-size: 1rem !important;
}

.doctorInfo_container .doctor-popup-main {
  margin: 0.5rem 0 0.5rem 0;
  width: 100%;
}

.doctorInfo_container .doctor-popup-content {
  padding: 0.5rem 0;
  font-size: 0.9rem;
  text-align: center;
}

.doctorInfo_container .popup-button-group {
  display: flex;
  flex-direction: column;
}

.doctorInfo_container .popup-button-group .customButton_container {
  height: 2rem;
  font-size: 0.8rem !important;
  line-height: 0.9rem !important;
}

.doctor-match-list {
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
  top: 2.8rem;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0 0.2rem -0.1rem #222;
  padding: 1rem 1rem 0;
}

.doctor-match-title {
  color: #e64638;
  font-size: 0.8rem;
}

.doctor-match-list::before {
  content: "";
  width: 0;
  height: 0;
  border-width: 0.5rem;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  position: absolute;
  right: 2.25rem;
  top: -1rem;
  z-index: 1001;
}

.doctor-match-ul {
  max-height: 9rem;
  overflow: scroll;
}

.doctor-match-li {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
}
.doctor_info_name .el-input.is-disabled .el-input__inner {
  color: #000;
}
.doctor-match-button {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-match-button .customButton_container {
  width: 4rem;
  height: 1.5rem !important;
}
.doctorInfo_container .el-textarea__inner {
  overflow: auto;
}
.doctorInfo_container .el-input__wrapper,
.doctorInfo_container .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.doctorInfo_container .el-input.is-disabled .el-input__wrapper {
  background-color: #fff;
}
.doctorInfo_container .el-textarea__inner,
.doctorInfo_container .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.doctorInfo_container .el-select .el-input.is-focus .el-input__wrapper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>